body {
  background: rgb(239, 236, 236);
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: #201b23;
  color: white;
  align-items: center;
  padding: 10px;
}

.promo {
  border: 1px solid #201b23;
  padding: 10px;
  font-size: 1em;
  font-weight: bold;
  width: 60%;
}

.branding {
  display: flex;
  align-items: center;
}
.branding img {
  width: 110px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.rocket-panel {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
  border: 1px solid #cccccc;
  background-color: white;
  margin-bottom: 10px;
  padding: 30px;
  border-radius: 5px;
  position: relative;

  /* Fade in the panel */
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rocket-panel p {
  margin: 0px;
  margin-bottom: 5px;
}

.rocket-inputs {
    text-align: left;
    border: 1px solid #cccc;
    padding: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 60px;
}

.rocket-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  border: 1px solid #e8e6e6;
  border-radius: 5px;
}

.action-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: rgba(50, 50, 50, 20);
  padding: 30px;
  color: white;
  justify-content: center;
}

.action-panel h2 {
  margin-bottom: 5px;
}

.action-panel p {
  margin-bottom: 20px;
}

.action-panel .close-panel {
  color: rgb(198, 18, 18);
  cursor: pointer;
  text-align: center;
  margin-top: 40px;
}

.action-panel .close-panel:hover {
  color: rgb(217, 0, 0);
}

.loading {
  opacity: .95;
  align-items: center;
  z-index: 100;
}

.action-panel.loading {
  min-height: 100px;
}

.loading .spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 10px;
  animation: rotation 1s linear infinite;
}


.sub-panel {
  background-color: rgba(204, 0, 0, .9);
  color: white;
  padding: 30px;
  border-radius: 5px;
}

.fee-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input, button {
  padding: 10px;
  margin: 5px 0px;
}

button {
  width: 100%;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.wallet-list {
  margin: 10px 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
}

.btn-action {
  display: block;
  color: black;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #201b23;
  border-radius: 5px;
  cursor: default; /* Default cursor when disabled */
}

.btn-action:not(:disabled) {
  cursor: pointer; /* Pointer cursor only when enabled */
}

.btn-action:not(:disabled):hover {
  background-color: #201b23;
  color: white;
}



.split-listitem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.split-details {
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #201b23;
  cursor: pointer;
  border-radius: 6px;
}

.split-details .rocketsplit-address {
  background-color: #2D2C2C;
  color: white;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
}

.split-details .rocketsplit-address span {
  font-size: 1.6em;
}

.split-details .rocketsplit-address .copy-mark {
  filter: invert(1);
}

.split-details .label {
  font-size: .8em;
}

.split-details .rocket-info-grid {
  /* Should span two columns in grid */
  grid-column: 1 / 3;

}

.split-details .rocketsplit-partner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  background-color: #f3f3f3;
  margin: 10px;
}

.split-details .btn-action {
  flex: .2;
  margin-top: 0;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.not-rocketsplit {
  color: red;
  padding: 10px;
  border: 1px solid red;
}

.is-rocketsplit {
  color: #32a700;
  padding: 10px;
  border: 1px solid #32a700;
  border-radius: 5px;
}

.wallet-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 10px 0;
  padding: 0px;
}

.wallet-actions li {
  display: block;
  list-style: none;
  border-radius: 5px;
}

.wallet-actions li div {
  font-size: .7em;
  text-align: center;
}

.large-address {
  font-size: 2.5em;
}

.toggle-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: grey;
  border-radius: 5px;
  background-repeat: no-repeat;
  padding: 0px;
  margin: 10px 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #32a700;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #32a70050 0px 0px 0px 8px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #32a70050 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ens-label {
  margin-top: -12px;
  font-size: 1em;
  margin-bottom: 10px;
}

.address-display .copy-mark {
  width: 10px;
}

.address-display .check-mark {
  width: 15px;
}

thead th {
  border-bottom: 1px solid #eeeeee;
}




@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}